import Home from "./pages/Home";
import 'bootstrap/dist/css/bootstrap.min.css';


const App = () => {
  return (
    <>
      <Home/>
      
    </>
    
  ) 
};

export default App;